import http from "@/getToken";

class Token {
  
   getToken(username, password) {     
    return http.post('/Authenticate', {
      username,
      password
  });
  }
}

export default new Token();