<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-content>
        <collapse :collapse="['Filtros de busqueda', 'Solicitud', 'Poliza', 'Solución']" icon="keyboard_arrow_down"
          color-collapse="success">
          <template slot="md-collapse-pane-1">
            <div class="md-layout-item md-size-100">
              <md-card>
                <md-card-header class="md-card-header-text md-card-header-primary">
                  <div class="card-icon">
                    <md-icon>search</md-icon>
                  </div>
                  <h4 class="title">Filtros</h4>
                </md-card-header>

                <md-card-content>
                  <div class="md-layout-item md-size-100 md-medium-size-100">
                    <label><strong>Datos Generales</strong></label>
                    <div class="md-layout">
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field>
                          <label>Solicitud Id</label>
                          <md-input v-model="solicitudId"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field>
                          <label>Póliza Id</label>
                          <md-input v-model="polizaId"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field>
                          <label>Solución Id</label>
                          <md-input v-model="solucionId"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field>
                          <label>Razón Social</label>
                          <md-input v-model="RasonSocial"></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-100 md-medium-size-100">
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100 md-medium-size-100">
                        <md-field>
                          <label>*Domicilio del bien a ser arrendado (Calle, No. Exterior y/o No. Interior)</label>
                          <md-input v-model="SolicitudUbicacionArrendado"></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-100 md-medium-size-100">
                    <label><strong>Busqueda por Nombre</strong></label>
                    <div class="md-layout">

                      <div class="md-layout-item md-size-33 md-medium-size-100">
                        <md-field>
                          <label>Nombre</label>
                          <md-input v-model="SolicitudNombreProp"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-33 md-medium-size-100">
                        <md-field>
                          <label>Apellido Paterno</label>
                          <md-input v-model="SolicitudApePaternoProp"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-33 md-medium-size-100">
                        <md-field>
                          <label>Apellido Materno</label>
                          <md-input v-model="SolicitudApeMaternoProp"></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>
                </md-card-content>
                <md-card-actions>
                  <md-button class="md-raised md-default" @click.native="Limpiar()">Limpiar</md-button>
                  <md-button class="md-raised md-primary" @click.native="Buscar()">Buscar</md-button>
                </md-card-actions>
                <md-progress-bar class="md-warning" md-mode="buffer" v-if="isLoader == true"></md-progress-bar>
              </md-card>
            </div>
          </template>
          <template slot="md-collapse-pane-2">
            <div class="md-layout-item md-size-100">
              <solicitudes v-bind:tableData="dataSolicitud" />
            </div>
          </template>
          <template slot="md-collapse-pane-3">
            <div class="md-layout-item md-size-100">
              <polizas v-bind:tableData="dataPolizas" />
            </div>
          </template>
          <template slot="md-collapse-pane-4">
            <div class="md-layout-item md-size-100">
              <soluciones v-bind:tableData="dataSoluciones" />
            </div>
          </template>
        </collapse>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { Collapse } from "@/components";
import solicitudes from "./OpcBusqueda/solicitudes.vue";
import servBusqueda from "@/Servicios/Busqueda/Busqueda.js";
import servSolicitudes from "@/Servicios/Solicitudes/solicitudes.js";
import polizas from "./OpcBusqueda/polizas.vue";
import servPolizas from "@/Servicios/Polizas/polizas.js";
import soluciones from "./OpcBusqueda/soluciones.vue";
import servSoluciones from "@/Servicios/Soluciones/soluciones.js";
export default {
  components: {
    Collapse,
    solicitudes,
    polizas,
    soluciones,
  },
  data() {
    return {
      dataSoluciones: [],
      dataPolizas: [],
      dataSolicitud: [],
      solicitudId: null,
      polizaId: null,
      solucionId: null,
      SolicitudUbicacionArrendado: "",
      SolicitudNombreProp: "",
      SolicitudApePaternoProp: "",
      SolicitudApeMaternoProp: "",
      RasonSocial: "",
      ListadoIdOperacionViewModel: null,
      isLoader: false,
    }
  },
  methods: {
    Buscar() {
      this.Limpiamos();
      this.isLoader = true;
      let data = {
        SolicitudId: this.solicitudId,
        PolizaId: this.polizaId,
        SolucionId: this.solucionId,
        SolicitudUbicacionArrendado: this.SolicitudUbicacionArrendado,
        SolicitudNombreProp: this.SolicitudNombreProp,
        SolicitudApePaternoProp: this.SolicitudApePaternoProp,
        SolicitudApeMaternoProp: this.SolicitudApeMaternoProp,
        RasonSocial: this.RasonSocial,
      }
      servBusqueda
        .post(
          data
        )
        .then((res) => {
          this.ListadoIdOperacionViewModel = res.data;
          this.isLoader = false;
          console.log("ListadoIdOperacionViewModel", this.ListadoIdOperacionViewModel.solicitudId);
          if (this.ListadoIdOperacionViewModel.solicitudId.length == 0) {
            this.$notify({
              message: "No se encontraron registros",
              timeout: 30000,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "warning",
            });
          } else {
            this.BuscamosSolicitudes();
          }
          if (this.ListadoIdOperacionViewModel.polizaId.length > 0) {
            this.BuscamosPolizas();
          }
          if (this.ListadoIdOperacionViewModel.solucionId.length > 0) {
            this.BuscamosSoluciones();
          }

        })
        .catch((err) => {
          this.isLoader = false;
          console.log(err);
        });
    },
    BuscamosSolicitudes() {
      let data = {
        SolicitudId : this.ListadoIdOperacionViewModel.solicitudId,
        UsuarioId : 0,
      }
      servSolicitudes
        .GetSolicitudes(data)
        .then((res) => {
          this.dataSolicitud = res.data.data;
          console.debug("dataSolicitud", this.dataSolicitud);
          // this.$emit("update:data", this.dataSolicitud);
          this.isLoader = false;
        }).catch((err) => {
          this.isLoader = false;
          console.log(err);
        });
    },
    BuscamosPolizas() {
      servPolizas.PolizasLista(this.ListadoIdOperacionViewModel.polizaId)
        .then(r => this.dataPolizas = r.data)
        .catch(err => console.log(err))
    },
    BuscamosSoluciones() {
      servSoluciones.getSolucionesList(this.ListadoIdOperacionViewModel.solucionId)
        .then(r => {
          this.dataSoluciones = r.data;
          console.log(this.dataSoluciones);
        })
        .catch(err => console.log(err));

    },
    Limpiar() {
      this.dataSolicitud = [];
      this.dataPolizas = [];
      this.dataSoluciones = [];
      this.solicitudId = 0;
      this.polizaId = 0;
      this.solucionId = 0;
      this.SolicitudUbicacionArrendado = "";
      this.SolicitudNombreProp = "";
      this.SolicitudApePaternoProp = "";
      this.SolicitudApeMaternoProp = "";
      this.SfisicaNombre = "";
      this.SfisicaApePat = "";
      this.SfisicaApeMat = "";
      this.FiadorNombres = "";
      this.FiadorApePaterno = "";
      this.FiadorApeMaterno = "";
      this.AsesorNombre = "";
      this.AsesorApePaterno = "";
      this.AsesorApeMaterno = "";
    },
    Limpiamos() {
      this.dataSolicitud = [];
      this.dataPolizas = [];
      this.dataSoluciones = [];
    }
  }
};
</script>

<style></style>