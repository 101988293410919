<template>
  <div class="flex h-screen">
    <div class="flex-1">
      <div v-if="showWelcome" class="welcome-container">
        <md-card class="welcome-card">
          <div class="text-center">
            <h1 class="welcome-text">{{ displayText }}</h1>

            <div v-if="!isTyping" class="search-container">
              <div class="relative">
                <input type="text" placeholder="¿Qué necesitas hacer hoy?" class="search-input" v-model="searchQuery"
                  @keyup.enter="handleSearch" :disabled="isLoading" />
                <div v-if="isLoading" class="loading-container">
                  <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <p class="loading-text">Procesando tu solicitud...</p>
                </div>
              </div>
            </div>
          </div>
        </md-card>
      </div>

      <div v-else>
        <component :is="currentComponent" v-bind="componentParams" v-if="currentComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Dashboard from '@/paginas/Metabase/Representacion.vue';
import solicitud from "@/paginas/Solicitudes/Solicitudes.vue";
import solucionesIndex from "@/paginas/Soluciones/Index.vue";
import listadoPolizas from "@/paginas/General/Polizas/listado.vue";
import crudlistasnegras from "@/paginas/Representaciones/listasnegras.vue";
import crudEventos from "@/paginas/Representaciones/eventos.vue";
import Calendar from "@/pages/Dashboard/Calendar.vue";
import movimientos from "@/paginas/Administracion/Movimientos/listado.vue";
import crudclienteusuarios from "@/paginas/Representaciones/clienteusuarios.vue";
import User from "@/paginas/User/UserProfile.vue";
import buscar from "@/paginas/General/Buscar.vue";

export default {
  name: 'DynamicComponentHandler',
  components: {
    Dashboard,
    solicitud,
    solucionesIndex,
    listadoPolizas,
    crudlistasnegras,
    crudEventos,
    Calendar,
    movimientos,
    crudclienteusuarios,
    User,
    buscar
  },
  computed: {
    ...mapState(['componenteBusqueda'])
  },
  data() {
    return {
      showWelcome: false,
      isTyping: true,
      isLoading: false,
      displayText: '',
      welcomeText: 'Es el momento de que solo te enfoques a vender y toda la gestión o administración de tu operación la hagamos en conjunto Póliza Jurídica IA',
      searchQuery: '',
      currentComponent: Dashboard,
      componentParams: {}
    }
  },
  mounted() {
    this.typeWriter()
  },
  methods: {
    typeWriter() {
      if (!this.welcomeText) return;

      let index = 0;
      const interval = setInterval(() => {
        if (index < this.welcomeText.length) {
          this.displayText += this.welcomeText.charAt(index);
          index++;
        } else {
          this.isTyping = false;
          clearInterval(interval);
        }
      }, 50);
    },
    async handleSearch() {
      if (!this.searchQuery || this.isLoading) return;
      
      this.isLoading = true;
      try {
        await this.$store.dispatch('processUnifiedSearch', this.searchQuery);
        this.showWelcome = false;
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isLoading = false;
        this.searchQuery = '';
      }
    }
  },
  watch: {
  componenteBusqueda: {
    handler(newVal) {
      if (newVal?.componente) {
        this.currentComponent = newVal.componente;
        this.componentParams = newVal.parametros;
      }
    },
    deep: true
  }
}
}
</script>
<style scoped>
.loading-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.loading-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.loading-dots span {
  width: 10px;
  height: 10px;
  background: #AF2327;
  border-radius: 50%;
  animation: bounce 0.5s alternate infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.16s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.32s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10px);
  }
}

.loading-text {
  margin-top: 0.5rem;
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
}

.search-input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.transition-all {
  transition: all 0.3s ease;
}

.iframe-container {
  width: 100%;
  height: 100vh;
}

.welcome-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
}

.welcome-text {
  font-size: 3rem;
  font-weight: 600;
  font-style: italic;
  font-family: 'Segoe UI', sans-serif;
  background: linear-gradient(120deg,
      #AF2327 0%,
      #9A9A9A 50%,
      #AF2327 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 4s linear infinite, fadeIn 1s ease-in;
  text-align: center;
  max-width: 800px;
  line-height: 1.4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 1rem 1.5rem;
  border-radius: 9999px;
  border: 2px solid #e2e8f0;
  background: white;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.search-input::placeholder {
  text-align: center;
  color: #a0aec0;
  font-style: italic;
}

.search-input:focus::placeholder {
  opacity: 0.5;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.welcome-card {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>