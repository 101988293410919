import { http } from "@/http-commons";
import { data } from "jquery";

class Solicitudes {
    // getSolicitudes(tipoProceso, fechaInicio, fechafin, renovacion, usuario) {
    //     return http.get(`/Solicitud/${tipoProceso}/${fechaInicio}/${fechafin}/${renovacion}/${usuario}`);
    // }
    BuscaSolicitud(id, usuarioid) {
        return http.get(`/Solicitud/solicitudespecifica/${id}/${usuarioid}`);
    }
    post(data) {
        return http.post('/Solicitud',data);
    }
    getId(id) {
        return http.get(`/Solicitud/${id}`);
    }
    GetSolicitudes(data) {        
        const params = {
            ...data,
            page: data.page || 1,
            pageSize: data.pageSize || 10
        };
        return http.post('/Solicitud/Solicitudes', params);
    }
    putSolicitud(id, data){
       return http.put(`/Solicitud/${id}`, data)
    }
    GetIndicesSolicitud(id){
        return http.get(`/Solicitud/GetIndicesSolicitud/${id}`);
    }
    reciclarSolicitud(id){
        return http.get(`/Solicitud/ReciclarSolicitud/${id}`)
    }
    jurisdiccion(id, jurisdiccion, data){
        return http.post(`/Solicitud/Jurisdiccion/${id}/${jurisdiccion}`, data);
    }
}

export default new Solicitudes();