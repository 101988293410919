import { http } from "@/http-commons";
// import SecurityService from "../../Auth/auth2";
import axios from 'axios';
class Login {

  login(usuario) {
    return http.post('/Login', usuario);
  }
  getIp() {
    return axios
      .get('https://api.ipify.org?format=json')
      .then(response => response.data)
      .catch(error => console.log(error));
  }
}

export default new Login();